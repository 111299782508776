html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.unstyled th,
.unstyled td {
  border: 1px solid #bcbbba;
  border-collapse: collapse;
}

.unstyled th,
.unstyled td:nth-child(1) {
  text-align: center;
  background-color: #fbfbfb;
  color: #5e5e5e;
}
.unstyled td {
  padding: 7px;
}
